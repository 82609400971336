<template>
  <div class="approve-reservation">
    <div class="card">
      <fd-form @submit="approveReservation">
        <modal-body class="p-3">
          <h4 class="mb-4">Approve Reservation</h4>

          <fd-date-picker
            v-model="form.paymentDueDate"
            label="Payment Due Date"
            :validators="[
              validator.required,
              (val) =>
                validator.dateMin(
                  val,
                  $moment().add(1, 'days').format('YYYY-MM-DD')
                )
            ]"
            :min="$moment().add(1, 'days').format('YYYY-MM-DD')"
          ></fd-date-picker>
        </modal-body>
        <modal-footer class="row justify-content-end p-2">
          <button type="button" class="btn mr-1" @click="$emit('cancel')">
            Cancel
          </button>
          <button class="btn main">Approve</button>
        </modal-footer>
      </fd-form>
    </div>
  </div>
</template>

<script>
import {
  required,
  dateMin
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  components: {},
  mixins: [],
  props: {
    reservationId: {
      type: [String, Number],
      required: true
    }
  },
  data: function () {
    return {
      form: {
        paymentDueDate: ""
      },

      validator: {
        required: required,
        dateMin: dateMin
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {
    approveReservation() {
      this.$emit("submit", this.reservationId, this.form);
    }
  }
};
</script>

<style lang="scss">
.approve-reservation {
  min-width: 500px;
}
</style>
